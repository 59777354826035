import React from 'react';
import { useTranslation } from 'react-i18next';
// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useAppVersion } from 'hooks/useAppVersion';

interface Props { }

const Copyright: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  const appVersion = useAppVersion(); 

  return (
    <div className={classes.root}>
      <Typography variant="caption">© {year} {t('copyright.text')} {appVersion}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    color: theme.palette.text.hint,
    textAlign: 'center',
  },
}));

export default Copyright;
