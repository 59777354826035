import { useQuery } from '@apollo/client';
import { GET_API_VERSION } from 'operations/queries/getApiVersion';

const frontEndVersion = '1.38.01';

export const useAppVersion = (): string => {
    const {data} = useQuery(GET_API_VERSION, {});

    const apiVersion = data?.getApiVersion;

    return `v${frontEndVersion}${apiVersion}`;
}